import { useParams } from '@solidjs/router'
import CompletedView from '~/components/completed-view/completed-view'
import { CompletedParams } from '~/types/routes/series/completed'

export default function Validation() {
  const { id } = useParams<CompletedParams>()
  return (
    <>
      <CompletedView chapterId={id} />
    </>
  )
}
